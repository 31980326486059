import React, { useEffect, useState } from "react"
import {
  Profile,
  LockIcon,
  LogoutIcon,
  PersonRedIcon,
  ArrowIcon,
  CloseIcon,
  GeoLocationIcon
} from "../../../Assets/index"
import { Menu, Transition } from "@headlessui/react"
import { useAppDispatch, useAppSelector } from "../../../store"
import { useNavigate } from "react-router-dom"
import { useLogoutMutation } from "../../../services/auth"
import {
  useCountUnreadNotificationQuery,
  useGetLatestNotificationQuery
} from "../../../services/notification"
import { LoadingCircle } from "../../atom/Loading/loadingCircle"
import { setBackUrl } from "./slice"
import getCredential from "../../../utils/getCredential"
import {
  setRefreshToken,
  setAccessToken,
  setRoles
} from "../../../services/auth/slice"
import { useGetProfileQuery } from "../../../services/users"
import { useDispatch } from "react-redux"
import { CancelModal } from "./CancelModal"
import { useTranslation, Trans } from "react-i18next"
import BellNotif from "./notification"
import moment from "moment"
import "moment/locale/id"
import { Dot } from "../../atom/dot"
moment.locale("id")

interface INavbar {
  open: boolean
  setOpen: (t: boolean) => void
}
export const Navbar: React.FC<INavbar> = (props) => {
  const { open, setOpen } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { title, backUrl, fullname } = useAppSelector((s) => s.navbarSlice)
  const code = localStorage.getItem("code")
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
  const [openPushNotification, setOpenPushNotification] =
    useState<boolean>(true)
  const interval = Number(process.env.REACT_APP_NOTIFICATION_INTERVAL)
  const notificationDate = moment(new Date()).format("YYYY-MM-DD").toString()
  const notificationTime = moment(new Date())
    .subtract(5, "minutes")
    .format("HH:mm:ss")

  const {
    data: dataProfile,
    isLoading: profileLoading,
    refetch
  } = useGetProfileQuery({}, { refetchOnMountOrArgChange: true })

  const { data: totalUnread, refetch: notificationRefetch } =
    useCountUnreadNotificationQuery(
      {},
      {
        pollingInterval: interval
      }
    )

  const { data: pushNotificationData, isLoading: pushNotificationLoading } =
    useGetLatestNotificationQuery(
      {
        notificationDate,
        notificationTime
      },
      {
        pollingInterval: interval
      }
    )

  useEffect(() => {
    if (dataProfile) {
      localStorage.setItem(
        "roles",
        JSON.stringify(dataProfile?.data?.role?.permissions?.split(","))
      )
    }
  }, [dataProfile])
  useEffect(() => {
    void refetch()
  }, [fullname])

  useEffect(() => {
    void notificationRefetch()
  }, [title])

  useEffect(() => {
    if (dataProfile) {
      localStorage.setItem("id", dataProfile.data.id)
      localStorage.setItem("currentRole", dataProfile.data.role.name)
    }
  }, [dataProfile, profileLoading])

  useEffect(() => {
    if (pushNotificationData) {
      pushNotificationData?.total <= 0 && setOpenPushNotification(false)
    }
  }, [pushNotificationData, pushNotificationLoading])

  const titleWithBackIcon =
    window.location.pathname.includes("/users") ||
    window.location.pathname.includes("/waiting-approval") ||
    window.location.pathname.includes("/umkm-list-member") ||
    window.location.pathname.includes("/approval-history") ||
    window.location.pathname.includes("/master-product") ||
    window.location.pathname.includes("/orders-and-transactions") ||
    window.location.pathname.includes("/role") ||
    window.location.pathname.includes("/kelola-dashboard") ||
    window.location.pathname.includes("/leads-data")

  const formWithBackIcon =
    window.location.pathname.includes("/users") ||
    window.location.pathname.includes("/master-product") ||
    window.location.pathname.includes("/role") ||
    window.location.pathname.includes("/kelola-dashboard") ||
    window.location.pathname.includes("/leads-data")

  return (
    <>
      {formWithBackIcon && (
        <CancelModal
          openCancelModal={openCancelModal}
          setOpenCancelModal={setOpenCancelModal}
          title={t("navbar.confirm-cancel") || ""}
        />
      )}

      <div
        className={`${
          open ? "lg:pl-[300px]" : "lg:pl-[100px]"
        } pl-[32px] w-full  h-[72px] border  pr-[32px] flex items-center justify-between fixed bg-white z-20`}
      >
        <svg
          onClick={() => {
            setOpen(!open)
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 cursor-pointer  lg:hidden visible"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>

        <div className="flex flex-row gap-2 items-center">
          {backUrl && titleWithBackIcon && (
            <ArrowIcon
              className="cursor-pointer"
              onClick={() => {
                if (formWithBackIcon) {
                  setOpenCancelModal(true)
                } else {
                  dispatch(setBackUrl(""))
                  navigate(-1)
                }
              }}
            />
          )}
          <h1 className="text-xl font-semibold">{title}</h1>
        </div>

        <div className="flex gap-[22px] items-center">
          {openPushNotification &&
            !pushNotificationLoading &&
            pushNotificationData &&
            pushNotificationData?.total > 0 && (
              <div className="w-[307px] h-[62px] border-solid border border-gray5 rounded-[12px] flex flex-row justify-between p-4 items-center">
                <div>
                  <p className="text-[16px] text-primary font-semibold">
                    {t("navbar.waiting-notification") || ""}
                  </p>
                  <p className="text-[12px] text-secondary font-medium">
                    <Trans
                      components={{ span: <span /> }}
                      values={{ total: pushNotificationData?.total || 0 }}
                    >
                      {t("navbar.waiting-notification-message") || ""}
                    </Trans>
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenPushNotification(false)
                  }}
                >
                  <img src={CloseIcon} width={12} height={12} />
                </div>
              </div>
            )}

          <BellNotif totalUnread={totalUnread} />
          <div className="flex flex-row items-center gap-2">
            <ProfilePicture />
            <div className="flex flex-col">
              <p className="text-[14px] font-semibold">
                {dataProfile ? dataProfile.data.fullName : ""}
              </p>
              <p className="text-[12px] text-secondary">
                {dataProfile ? dataProfile.data.role.name : ""}
                {code && (
                  <>
                    {" "}
                    <Dot size={7} />
                    {code}
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const ProfilePicture: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [logout, { isLoading }] = useLogoutMutation()
  const { t } = useTranslation()

  const actionLogout = (): void => {
    dispatch(setAccessToken(""))
    dispatch(setRefreshToken(""))
    dispatch(setRoles([]))
    localStorage.removeItem("WYZTKN")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("roles")
    localStorage.removeItem("username")
    localStorage.removeItem("code")
    localStorage.removeItem("id")
    localStorage.removeItem("currentRole")
    localStorage.removeItem("needPassAdjustment")
    navigate("/login")
  }
  const onLogout = (): void => {
    logout({ refreshToken: getCredential().refreshToken })
      .unwrap()
      .then((d) => {
        actionLogout()
      })
      .catch(() => {
        actionLogout()
      })
  }
  return (
    <>
      <Menu as="div" className="relative inline-block text-left z-[999]">
        <Menu.Button className={"h-[56px]"}>
          <div className="w-[42px] h-[42px] rounded-full bg-pink1 flex justify-center items-center cursor-pointer">
            <img src={Profile} alt="Profile" className="w-6" />
          </div>
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={
              "bg-[#FFFF] fixed md:absolute lg:-right-6 -right-0 mt-3 lg:-translate-x-0 w-80 -translate-x-0  lg:w-[220px]  divide-y  rounded-md  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            }
          >
            <Menu.Item>
              {({ close }) => (
                <div
                  className="flex space-x-4 p-4 cursor-pointer hover:bg-lightgray3 items-center"
                  onClick={() => {
                    navigate("/profile")
                    close()
                  }}
                >
                  <PersonRedIcon />
                  <h1 className="text-[16px] font-semi-bold">
                    {t("button.profile") || ""}
                  </h1>
                </div>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ close }) => (
                <div
                  className="flex space-x-4 p-4 cursor-pointer hover:bg-lightgray3 items-center"
                  onClick={() => {
                    navigate("/change-password")
                    close()
                  }}
                >
                  <LockIcon />
                  <h1 className="text-[16px] font-semi-bold">
                    {t("button.change-password") || ""}
                  </h1>
                </div>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ close }) => (
                <div
                  className="flex space-x-4 p-4 cursor-pointer hover:bg-lightgray3 items-center"
                  onClick={() => {
                    navigate("/location")
                    close()
                  }}
                >
                  <GeoLocationIcon />
                  <h1 className="text-[16px] font-semi-bold">
                    {t("button.location") || ""}
                  </h1>
                </div>
              )}
            </Menu.Item>

            <div
              className="flex space-x-4 p-4 cursor-pointer  hover:bg-lightgray3  items-center"
              onClick={onLogout}
            >
              <LogoutIcon />
              <h1 className="text-[16px] font-semi-bold">
                {t("button.logout") || ""}
              </h1>
              {isLoading && <LoadingCircle />}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
