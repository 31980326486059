import React, { Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import Loading from "../Components/atom/Loading"
import NotFound from "../pages/NotFound"
import { ProtectedRoute } from "./AppAutenticate"
import PrivacyPolicyPage from "../pages/PrivacyPage"
import WhatIsPage from "../pages/PrivacyPage/what"
import PrivacyAndPolicyWizPage from "../pages/PrivacyPage/policy"
const Login = React.lazy(async () => await import("../pages/Login/index"))
const ForgotPassword = React.lazy(
  async () => await import("../pages/ForgotPassword")
)
const RenewPassword = React.lazy(
  async () => await import("../pages/RenewPassword")
)
/* eslint-disable */
const AppUnauthenticatedPage = ({ token }: { token: any }) => {
  return (
    <div>
      <Routes>
        <Route>
          <Route path="*" element={<NotFound />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/canvasser/privacy-policy" element={<PrivacyAndPolicyWizPage />} />
          <Route path="/canvasser/what" element={<WhatIsPage />} />
          <Route element={<ProtectedRoute token={!token} redirectPath="/" />}>
            <Route
              path="/login"
              element={
                <Suspense fallback={<Loading />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="/renew-password"
              element={
                <Suspense fallback={<Loading />}>
                  <RenewPassword />
                </Suspense>
              }
            />
          </Route>

          <Route index path="forgot-password" element={<ForgotPassword />} />
        </Route>
      </Routes>
    </div>
  )
}
export default AppUnauthenticatedPage
