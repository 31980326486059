import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type {
  IResponse,
  IGetUserList,
  IGetUserByID,
  IGetUserByIDResponse,
  IGetUserRoleList,
  IGetUserRoleListResponse,
  ICreateUser,
  ICreateUserResponse,
  IUpdateUser,
  IUpdateUserResponse,
  IUpdateUserProfile,
  IUpdateUserProfileResponse,
  IResetPassword,
  IDeleteUser,
  IChangePassword,
  ILocation,
  IResponseSaldoBalance,
  IResponseSaldoHistory
} from "./types"

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getUserList: builder.query<any, IGetUserList>({
      query: ({ pageIndex, limit, sortBy, direction, search }) => ({
        url: API.USER.LIST_USER,
        method: "GET",
        params: {
          pageIndex,
          limit,
          sortBy,
          direction,
          search
        }
      }),
      transformResponse: (res: any) => {
        const data = res.data.map((data: any, i: number) => ({
          ...{
            id: data.id,
            fullName: data.fullName,
            username: data.username,
            role: data.role.name,
            phoneNumber: data.phoneNumber,
            email: data.email,
            isActived: data.actived,
            user: {
              id: data.id,
              name: data.fullName,
              username: data.username
            }
          }
        }))
        data.total = res.total
        res = data
        return res
      }
    }),
    getUserRoleList: builder.query<IGetUserRoleListResponse, IGetUserRoleList>({
      query: ({ pageIndex, limit }) => ({
        url: API.USER.LIST_ROLE_USER,
        method: "GET",
        params: {
          pageIndex,
          limit
        }
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = res.data.map((data: any, i: number) => ({ ...data }))
        res = data
        return res
      }
    }),
    getUserById: builder.query<IGetUserByIDResponse, IGetUserByID>({
      query: ({ id }) => ({
        url: `${API.USER.DETAIL_USER}/${id}`,
        method: "GET"
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = {
          fullName: res.data.fullName,
          phoneNumber: res.data.phoneNumber,
          address: res.data.address,
          gender: res.data.gender,
          username: res.data.username,
          email: res.data.email,
          role: res.data.role,
          actived: res.data.actived
        }
        res = data
        return res
      }
    }),
    createUser: builder.mutation<ICreateUserResponse, ICreateUser>({
      query: (payload) => ({
        url: API.USER.CREATE_USER,
        method: "POST",
        body: payload
      })
    }),
    updateUser: builder.mutation<IUpdateUserResponse, IUpdateUser>({
      query: (payload) => ({
        url: API.USER.UPDATE_USER,
        method: "PUT",
        body: payload
      })
    }),
    resetPassword: builder.mutation<any, IResetPassword>({
      query: (payload) => ({
        url: API.USER.RESET_PASSWORD,
        method: "POST",
        body: payload
      })
    }),
    deleteUser: builder.mutation<boolean, IDeleteUser>({
      query: (payload) => ({
        url: API.USER.DELETE_USER,
        method: "DELETE",
        body: payload
      })
    }),
    updateUserProfile: builder.mutation<
      IUpdateUserProfileResponse,
      IUpdateUserProfile
    >({
      query: (payload) => ({
        url: API.USER.UPDATE_PROFILE_USER,
        method: "PUT",
        body: payload
      })
    }),
    changePassword: builder.mutation<any, IChangePassword>({
      query: (payload) => ({
        url: API.USER.UPDATE_PASSWORD,
        method: "PUT",
        body: payload
      })
    }),
    getProfile: builder.query<IResponse, any>({
      query: () => ({
        url: API.USER.GET_PROFILE,
        method: "GET"
      })
    }),
    getLocation: builder.query<IResponse, any>({
      query: () => ({
        url: API.USER.GET_LOCATION_USER,
        method: "GET"
      })
    }),
    updateLocation: builder.mutation<IResponse, ILocation>({
      query: (payload) => ({
        url: API.USER.UPDATE_LOCATION_USER,
        method: "PUT",
        body: payload
      })
    }),
    getSaldoBalance: builder.query<IResponseSaldoBalance, undefined>({
      query: () => ({
        url: API.SALDO.BALANCE,
        method: "GET"
      })
    }),
    getSaldoHistory: builder.query<IResponseSaldoHistory, { filterByDate: string }>({
      query: (param) => ({
        url: API.SALDO.HISTORY,
        method: "GET",
        params: param
      })
    })
  })
})

export const {
  useGetUserListQuery,
  useGetUserByIdQuery,
  useGetUserRoleListQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserProfileMutation,
  useResetPasswordMutation,
  useDeleteUserMutation,
  useChangePasswordMutation,
  useGetProfileQuery,
  useGetLocationQuery,
  useUpdateLocationMutation,
  useGetSaldoBalanceQuery,
  useGetSaldoHistoryQuery
} = usersApi
