import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

interface IUnitData {
  id: number
  code: string
  name: string
  description: string
  totalProduct: number
}

export interface IUnit {
  openAddModal: boolean
  openEditModal: boolean
  unitData: IUnitData
}
const initialState: IUnit = {
  openAddModal: false,
  openEditModal: false,
  unitData: {
    id: 0,
    code: "",
    name: "",
    description: "",
    totalProduct: 0
  }
}

const unitSlice = createSlice({
  name: "unitSlice",
  initialState,
  reducers: {
    setOpenAddModal: (state, action: PayloadAction<boolean>) => {
      state.openAddModal = action.payload
    },
    setOpenEditModal: (state, action: PayloadAction<boolean>) => {
      state.openEditModal = action.payload
    },
    setUnitCode: (state, action: PayloadAction<IUnitData>) => {
      state.unitData = action.payload
    }
  }
})

export const { setOpenAddModal, setOpenEditModal, setUnitCode } =
  unitSlice.actions
export default unitSlice.reducer
