import React, { useEffect } from "react"
import { useAppSelector } from "../store"
import AppAuthenticatedPage from "./AppAutenticate"
import AppUnauthenticatedPage from "./AppUnautenticate"
import { useCheckConnection } from "../hooks/checkConnection"
import { showErrorMessage } from "../utils/showMessage"
import { useTranslation } from "react-i18next"

const RoutePage: React.FC = () => {
  const { accessToken } = useAppSelector((s) => s.authSlice)
  const { t } = useTranslation()
  const checkConnection = useCheckConnection()
  const needPassAdjustment =
    localStorage.getItem("needPassAdjustment") === "true"

  useEffect(() => {
    if (!checkConnection) {
      showErrorMessage(t("toast.error-no-internet"))
    }
  }, [checkConnection])

  return (
    <>
      {accessToken === "" || needPassAdjustment ? (
        <AppUnauthenticatedPage token={accessToken} />
      ) : (
        <AppAuthenticatedPage token={accessToken} />
      )}
    </>
  )
}
export default RoutePage
