import {
  BellRead,
  BellUnread,
  ShopRedIcon,
  Profile,
  DocumentRedIcon,
  DateRedIcon
} from "../../../Assets"
import React, { useRef, useState, useEffect } from "react"
import {
  useReadNotificationMutation,
  useListNotificationMutation
} from "../../../services/notification"
import { useTranslation, Trans } from "react-i18next"
import { Menu, Transition } from "@headlessui/react"
import type { INotification } from "../../../services/notification/types"
import { useDetectBottom } from "../../../hooks/useDetectBottom"
import { useNavigate } from "react-router-dom"
import {
  checkPermission,
  PERMISSION_LIST
} from "../../../utils/checkPermission"
import { useLazyGetWaitingListQuery } from "../../../services/umkm"
import { useAppDispatch } from "../../../store"
import { openToast } from "../Toast/slice"
import moment from "moment"
import "./style.css"
import { truncateString } from "../../../utils/helpersFunction"

interface IBellNotif {
  totalUnread: any
}

const BellNotif: React.FC<IBellNotif> = ({ totalUnread }) => {
  return (
    <Menu as="div" className="relative inline-block text-left z-[999]">
      <Menu.Button className={"h-[56px]"}>
        {totalUnread &&
        totalUnread.total > 0 &&
        (checkPermission(PERMISSION_LIST.UMKM_HISTORY_APPROVAL_VIEW) ||
          checkPermission(PERMISSION_LIST.TRANSACTION_VIEW)) ? (
          <div className="w-[42px] h-[42px] rounded-full bg-pink1 flex justify-center items-center cursor-pointer">
            <div className="relative">
              <img src={BellUnread} alt="Profile" className="w-5" />
              <div
                className={`absolute -top-2 left-2 ${
                  totalUnread.total > 99 ? "w-[25px] " : "w-[19px]"
                } h-[19px] rounded-full border-[1px] border-white bg-primary flex justify-center items-center text-white text-[11px] font-semibold`}
              >
                {totalUnread.total > 99 ? "99+" : totalUnread.total}
              </div>
            </div>
          </div>
        ) : (
          <div className="relative cursor-pointer">
            <img src={BellRead} alt="Notif" className="w-6 " />
          </div>
        )}
      </Menu.Button>
      <Transition
        as="div"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <NotificationList />
      </Transition>
    </Menu>
  )
}

const NotificationList: React.FC = () => {
  const ref = useRef(null)
  const [listNotification, { isLoading }] = useListNotificationMutation()
  const [list, setList] = useState<INotification[]>([])
  const [page, setPage] = useState(0)
  const [isInfinity, setIsInfinity] = useState(true)
  const { isVisible } = useDetectBottom(ref)
  // const { data: notification } = useListNotificationQuery({
  //   limit: 10,
  //   pageIndex: 1
  // })

  const { t } = useTranslation()
  const getList = (): void => {
    listNotification({
      limit: 10,
      pageIndex: page
    })
      .unwrap()
      .then((res) => {
        if (res.data.length) {
          setIsInfinity(true)
        } else {
          setIsInfinity(true)
        }
        setList([...list, ...res.data])
        setPage(page + 1)
      })
      .catch(() => {})
  }
  useEffect(() => {
    if (isVisible && isInfinity) {
      getList()
    }
  }, [isVisible])

  return (
    <Menu.Items
      className={
        "fixed md:absolute lg:-right-6 -right-0 mt-3 lg:-translate-x-0 w-80 h-80 md:h-[427px] -translate-x-0  lg:w-[392px]  divide-y  rounded-md  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-white "
      }
    >
      <div className="p-[16px]">
        <h1 className="text-[16px] font-semibold">{t("notification.title")}</h1>
      </div>
      <div className="overflow-y-auto h-[87%] rounded-b-lg">
        {list.map((d, i) => (
          <Menu.Item key={i}>
            {({ close }) => <Notification notification={d} close={close} />}
          </Menu.Item>
        ))}
        <div ref={ref} className="flex justify-center pb-10 pt-5">
          <p>{list.length > 5 && isLoading ? "Loading...." : ""}</p>
        </div>
      </div>
    </Menu.Items>
  )
}
interface Iprops {
  notification: INotification
  close: () => void
}
const Notification: React.FC<Iprops> = (props) => {
  const { t } = useTranslation()
  const [readNotification] = useReadNotificationMutation()
  const { notification, close } = props
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [waitingId] = useLazyGetWaitingListQuery()

  const renderTitle = (): string => {
    if (notification.type === "LOAN_REGISTRATION_DISTRIBUTOR") {
      return t("notification.loan-registration-title")
    } else if (notification.type === "TRANSACTION_DISTRIBUTOR") {
      if (
        notification.status === "CANCELLED_BY_UMKM" ||
        notification.status === "CANCELLED_BY_DISTRIBUTOR" ||
        notification.status === "REJECTED_BY_DISTRIBUTOR" ||
        notification.status === "REJECTED_BY_SYSTEM"
      ) {
        return t("notification.transaction-distributor-cancel-title")
      } else {
        return t("notification.transaction-distributor-title")
      }
    } else if (
      notification.type === "TRANSACTION_UMKM_UPDATE_ETD" ||
      notification.type === "TRANSACTION_UMKM_UPDATE_ETD_CANVASSER" ||
      notification.type === "TRANSACTION_UMKM_UPDATE_CANVASSER"
    ) {
      return t("notification.transaction-umkm-update-etd-title")
    } else {
      return ""
    }
  }
  const renderMessage = (): string => {
    if (notification.type === "LOAN_REGISTRATION_DISTRIBUTOR") {
      if (notification.status === "WAITING_APPROVAL") {
        return "notification.loan-registration-waiting-approval"
      } else if (notification.status === "DISTRIBUTOR_APPROVED") {
        return "notification.loan-registration-approved"
      } else if (notification.status === "DISTRIBUTOR_REJECTED") {
        return "notification.loan-registration-rejected"
      } else if (notification.status === "BANK_APPROVED") {
        return "notification.loan-registration-bank-approved"
      } else if (notification.status === "BANK_REJECTED") {
        return "notification.loan-registration-bank-rejected"
      } else {
        return ""
      }
    } else if (notification.type === "TRANSACTION_DISTRIBUTOR") {
      if (
        notification.status === "WAITING_CONFIRMATION" ||
        notification.status === "WAITING_APPROVAL"
      ) {
        return "notification.transaction-distributor-waiting-approval"
      } else if (notification.status === "CANCELLED_BY_UMKM") {
        return "notification.transaction-distributor-canceled-by-umkm"
      } else {
        return ""
      }
    } else if (
      notification.type === "TRANSACTION_UMKM_UPDATE_ETD" ||
      notification.type === "TRANSACTION_UMKM_UPDATE_ETD_CANVASSER" ||
      notification.type === "TRANSACTION_UMKM_UPDATE_CANVASSER"
    ) {
      if (notification.status === "ON_DELIVERY") {
        return "notification.transaction-umkm-update-etd"
      } else {
        return ""
      }
    } else {
      return ""
    }
  }

  const renderIcon = (): React.ReactNode => {
    if (notification.type === "LOAN_REGISTRATION_DISTRIBUTOR") {
      return <DocumentRedIcon className="w-6" />
    } else if (notification.type === "TRANSACTION_DISTRIBUTOR") {
      return <ShopRedIcon className="w-6" />
    } else if (
      notification.type === "TRANSACTION_UMKM_UPDATE_ETD" ||
      notification.type === "TRANSACTION_UMKM_UPDATE_ETD_CANVASSER" ||
      notification.type === "TRANSACTION_UMKM_UPDATE_CANVASSER"
    ) {
      return <DateRedIcon className="w-6" />
    } else {
      return <img src={Profile} alt="Profile" className="w-6" />
    }
  }

  const renderTime = (): string => {
    const { createdAt } = notification
    const today = moment().startOf("day")
    const yesterday = moment().subtract(1, "days").startOf("day")
    if (moment(createdAt).isSame(today, "day")) {
      return `${t("notification.notification-day-today")} ${moment(
        createdAt
      ).format("HH:mm")}`
    } else if (moment(createdAt).isSame(yesterday, "day")) {
      return `${t("notification.notification-day-yesterday")} ${moment(
        createdAt
      ).format("HH:mm")}`
    } else {
      return moment(createdAt).format("DD MMM YYYY HH:mm")
    }
  }

  const onClickDetail = (): void => {
    const payload = {
      id: notification.id,
      isRead: true
    }
    void readNotification(payload).unwrap()

    if (notification.type === "LOAN_REGISTRATION_DISTRIBUTOR") {
      if (notification.status === "WAITING_APPROVAL") {
        waitingId({
          pageIndex: 0,
          limit: 1,
          filterByAge: "",
          filterByGender: "",
          filterByStatus: "",
          filterBySubmissionDate: "",
          filterByUmkmType: "",
          search: notification.memberId,
          sortBy: "id",
          direction: "asc",
          isLead: ""
        })
          .unwrap()
          .then((res) => {
            close()
            navigate(`/waiting-approval/${res.data[0].id}`)
          })
          .catch((err) => {
            console.error(err)
            dispatch(
              openToast({
                type: "error",
                title: "Ditemukan kesalahan",
                message: "Data ini tidak tersedia"
              })
            )
          })
      } else if (
        notification.status === "DISTRIBUTOR_APPROVED" ||
        notification.status === "BANK_APPROVED"
      ) {
        close()
        navigate(`/umkm-list-member/${notification.memberId}`)
      } else if (
        notification.status === "DISTRIBUTOR_REJECTED" ||
        notification.status === "BANK_REJECTED"
      ) {
        close()
        navigate(`/approval-history/${notification.memberId}`)
      }
    }

    if (
      notification.type === "TRANSACTION_DISTRIBUTOR" ||
      notification.type === "TRANSACTION_UMKM_UPDATE_ETD" ||
      notification.type === "TRANSACTION_UMKM_UPDATE_ETD_CANVASSER" ||
      notification.type === "TRANSACTION_UMKM_UPDATE_CANVASSER"
    ) {
      close()
      navigate(`/orders-and-transactions/${notification.transactionId}`)
    }
  }

  return (
    <div
      onClick={onClickDetail}
      className={`${
        notification.read ? "bg-white" : "bg-pink1"
      } p-[16px] flex gap-[12px] items-start  cursor-pointer`}
    >
      <div
        style={{ backgroundColor: "rgba(255, 35, 75, 0.12)" }}
        className="w-[42px] h-[42px] rounded-xl  flex justify-center items-center cursor-pointer"
      >
        {renderIcon()}
      </div>
      <div>
        <div className="flex items-center gap-[8px] mb-1">
          <p className=" text-xs font-semibold text-info">{renderTitle()} </p>
          <div className="w-1 h-1 bg-gray4 rounded-full"></div>
          <p className="text-xs font-semibold text-secondary">
            {renderTime()}{" "}
          </p>
        </div>
        <p className="notif-msg text-sm font-normal max-w-[270px]">
          <Trans
            components={{ span: <span /> }}
            values={{
              memberId:
                notification.type === "LOAN_REGISTRATION_DISTRIBUTOR"
                  ? truncateString(notification.memberId, 16)
                  : truncateString(notification.transactionId, 21)
            }}
          >
            {renderMessage()}
          </Trans>
          {notification.status === "CANCELLED_BY_UMKM" ||
          notification.status === "ON_DELIVERY"
            ? truncateString(notification.createdBy, 16) || "-"
            : ""}
        </p>
      </div>
    </div>
  )
}
export default BellNotif
