import React, { useState } from "react"
import Card from "../../Components/atom/card"
import { useChangePasswordMutation } from "../../services/users"
import { EyeIcon, EyeSlashIcon } from "../../Assets"
import { TextInput } from "../../Components/atom/input"
import { Button } from "../../Components/atom/button"
import { LoadingCircle } from "../../Components/atom/Loading/loadingCircle"
import { useAppDispatch } from "../../store"
import { setTitle } from "../../Components/molecules/Navbar/slice"
import { validatePassword } from "../../utils/validationFunctions"
import {
  errorMessage,
  showErrorMessage,
  showSuccessMessage
} from "../../utils/showMessage"
import { useTranslation } from "react-i18next"

const ChangePassword: React.FC = () => {
  /* eslint-disable */
  const { t } = useTranslation()
  const [isConfirmPassword, setIsConfirmPassword] = useState<boolean>(true)
  const [isCurrentPassword, setIsCurrentPassword] = useState<boolean>(true)
  const [isNewPassword, setIsNewPassword] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const [error, setError] = useState<boolean>(false)
  const [errorConfirm, setErrorConfirm] = useState<boolean>(false)
  const [errorNew, setErrorNew] = useState<boolean>(false)
  const [changePasswordUser, { isLoading }] = useChangePasswordMutation()
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  })
  React.useEffect(() => {
    dispatch(setTitle("Ubah Password"))
  }, [])
  const handleSubmit = async (): Promise<void> => {
    if (formData.confirmPassword !== formData.newPassword) {
      setErrorConfirm(true)
    } else {
      try {
        const changePassword = await changePasswordUser({
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword
        }).unwrap()
        if (changePassword) {
          showSuccessMessage(t("toast.success-update-password"))
          setFormData({
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
          })
        }
      } catch (err: any) {
        if (err.data.status === 400) {
          err.data.message.includes("Wrong Old Password!") ||
          err.data.message.includes("Old Password is Required!") ||
          err.data.message.includes("Can not Update Using Same Password")
            ? errorMessage(err, null)
            : showErrorMessage(t("toast.error-invalid-form"))
        } else {
          errorMessage(err, null)
        }
      }
    }
  }

  return (
    <div className="w-[500px]">
      <Card
        width={120}
        padding={0}
        component={
          <div className="p-[1em]">
            <TextInput
              className={
                formData.oldPassword
                  ? "bg-white"
                  : "bg-lightgray1" + " text-[14px] text-gray1"
              }
              value={formData.oldPassword}
              onChange={(e) => {
                setFormData({ ...formData, oldPassword: e.target.value })
              }}
              placeholder="Masukkan Password Saat Ini"
              label={"Password Saat Ini"}
              type={isCurrentPassword === true ? "password" : "text"}
              rightComponent={
                <div
                  className="cursor-pointer ml-[15px] w-[25px]"
                  onClick={() => {
                    setIsCurrentPassword(!isCurrentPassword)
                  }}
                >
                  {isCurrentPassword === true ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              }
              error={error}
            />
            <TextInput
              className={
                formData.newPassword
                  ? "bg-white"
                  : "bg-lightgray1" + " text-[14px] text-gray1"
              }
              value={formData.newPassword}
              onChange={(e) => {
                setFormData({ ...formData, newPassword: e.target.value })
                if (
                  !validatePassword(e.target.value) ||
                  e.target.value.length < 8
                ) {
                  setErrorNew(true)
                } else if (
                  validatePassword(e.target.value) ||
                  e.target.value.length < 8
                ) {
                  setErrorNew(false)
                }
                if (formData.confirmPassword) {
                  if (e.target.value !== formData.confirmPassword) {
                    setErrorConfirm(true)
                  } else if (e.target.value === formData.confirmPassword) {
                    setErrorConfirm(false)
                  }
                }
              }}
              placeholder="Masukkan Password Baru"
              errorText={
                "Panjang karakter harus lebih dari 8 dan menggunakan (a-z),(A-Z),(0-9)"
              }
              label={"Password Baru"}
              type={isNewPassword === true ? "password" : "text"}
              rightComponent={
                <div
                  className="cursor-pointer ml-[15px] w-[25px]"
                  onClick={() => {
                    setIsNewPassword(!isNewPassword)
                  }}
                >
                  {isNewPassword === true ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              }
              error={errorNew}
            />
            <TextInput
              className={
                formData.confirmPassword
                  ? "bg-white"
                  : "bg-lightgray1" + " text-[14px] text-gray1"
              }
              value={formData.confirmPassword}
              // onKeyDown={(e)=>{
              //   console
              // }}
              onChange={(e) => {
                setFormData({ ...formData, confirmPassword: e.target.value })
                if (
                  !validatePassword(e.target.value) ||
                  e.target.value.length < 8 ||
                  e.target.value !== formData.newPassword
                ) {
                  setErrorConfirm(true)
                } else if (
                  validatePassword(e.target.value) ||
                  e.target.value.length < 8 ||
                  e.target.value === formData.newPassword
                ) {
                  setErrorConfirm(false)
                }
              }}
              placeholder="Masukkan Konfirmasi Password Baru"
              errorText={
                formData.confirmPassword !== formData.oldPassword
                  ? "Password yang anda masukan tidak sesuai"
                  : "Panjang karakter harus lebih dari 8 dan menggunakan (a-z),(A-Z),(0-9)"
              }
              label={"Konfirmasi Password Baru"}
              type={isConfirmPassword === true ? "password" : "text"}
              rightComponent={
                <div
                  className="cursor-pointer ml-[15px] w-[25px]"
                  onClick={() => {
                    setIsConfirmPassword(!isConfirmPassword)
                  }}
                >
                  {isConfirmPassword === true ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              }
              error={errorConfirm}
            />
            <div className="relative mt-16">
              <Button
                className="right-0 bottom-0 absolute"
                disabled={
                  errorConfirm ||
                  errorNew ||
                  error ||
                  !formData.oldPassword ||
                  !formData.confirmPassword ||
                  !formData.newPassword
                }
                onClick={handleSubmit}
              >
                {isLoading ? <LoadingCircle /> : "Ubah Password"}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default ChangePassword
