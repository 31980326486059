import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { basicQuery } from "../interceptor"
import type { ISendEmail, IResetPassword, ICheckToken } from "./types"

export const forgotPasswordApi = createApi({
  reducerPath: "forgotPasswordApi",
  baseQuery: basicQuery,
  endpoints: (builder) => ({
    sendEmail: builder.mutation<any, ISendEmail>({
      query: (payload) => ({
        url: "/oauth/dist/forgot-password",
        method: "POST",
        body: payload
      })
    }),
    resetPassword: builder.mutation<any, IResetPassword>({
      query: (payload) => ({
        url: "/oauth/dist/forgot-password/reset",
        method: "POST",
        body: payload
      })
    }),
    checkToken: builder.mutation<any, ICheckToken>({
      query: (payload) => ({
        url: "/oauth/dist/forgot-password/check",
        method: "POST",
        body: payload
      })
    })
  })
})

export const {
  useSendEmailMutation,
  useResetPasswordMutation,
  useCheckTokenMutation
} = forgotPasswordApi
