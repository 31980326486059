import React from "react"
import { MetaLogo, BankDKILogo } from "../../../Assets"

interface ILogoCard {
  className?: string
}

export const LogoCard: React.FC<ILogoCard> = ({ className }) => {
  return (
    <div className="mt-5 pb-2">
      <div className="flex flex-row justify-between px-[60px]">
        <p className="text-[12px] text-secondary">Present by:</p>
        <p className="text-[12px] text-secondary">Support by:</p>
      </div>
      <div className="flex flex-row justify-between items-center px-10 w-full">
        <div className="flex flex-col items-center justify-between w-[40%]">
          <MetaLogo />
        </div>

        <span className="w-[2px] h-[60px] bg-gray-100" />

        <div className="flex flex-col items-center gap-2 w-[40%]">
          <BankDKILogo className="w-[105px] h-auto" />
        </div>
      </div>
    </div>
  )
}

export const LogoCard2: React.FC<ILogoCard> = ({ className }) => {
  return (
    <div className="mt-5 pb-2">
      <div className="flex flex-row justify-between px-[30px]">
        <p className="text-[12px] text-secondary">Present by:</p>
        <p className="text-[12px] text-secondary">Support by:</p>
      </div>
      <div className="flex flex-row justify-between items-center px-10 w-full">
        <div className="flex flex-col items-center justify-between w-[20%]">
          <MetaLogo />
        </div>

        <span className="w-[2px] h-[60px] mr-4 bg-gray-100" />

        <div className="flex flex-col items-center gap-2 w-[20%]">
          <BankDKILogo className="w-[100px] mr-4 h-auto" />
        </div>
      </div>
    </div>
  )
}
