import React from "react"

interface DotProps {
  size: number
}

export const Dot: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    DotProps
> = ({ size }) => {
  const dotStyle: React.CSSProperties = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "4px"
  }

  return <span className="bg-lightgray2" style={dotStyle}></span>
}
