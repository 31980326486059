import getCredential from "./getCredential"
const PERMISSION_LIST = {
  ROLE_VIEW: "ROLE_VIEW",
  ROLE_CREATE: "ROLE_CREATE",
  ROLE_UPDATE: "ROLE_UPDATE",
  ROLE_DELETE: "ROLE_DELETE",
  USER_VIEW: "USER_VIEW",
  USER_CREATE: "USER_CREATE",
  USER_UPDATE: "USER_UPDATE",
  USER_DELETE: "USER_DELETE",
  USER_CHANGE_PASSWORD: "USER_CHANGE_PASSWORD",
  UMKM_WAITING_APPROVAL_VIEW: "UMKM_WAITING_APPROVAL_VIEW",
  UMKM_WAITING_APPROVAL_APPROVE: "UMKM_WAITING_APPROVAL_APPROVE",
  UMKM_WAITING_APPROVAL_REJECT: "UMKM_WAITING_APPROVAL_REJECT",
  UMKM_MEMBER_VIEW: "UMKM_MEMBER_VIEW",
  UMKM_HISTORY_APPROVAL_VIEW: "UMKM_HISTORY_APPROVAL_VIEW",
  PRODUCT_VIEW: "PRODUCT_VIEW",
  PRODUCT_CREATE: "PRODUCT_CREATE",
  PRODUCT_UPDATE: "PRODUCT_UPDATE",
  PRODUCT_DELETE: "PRODUCT_DELETE",
  PRODUCT_CATEGORY_VIEW: "PRODUCT_CATEGORY_VIEW",
  PRODUCT_CATEGORY_CREATE: "PRODUCT_CATEGORY_CREATE",
  PRODUCT_CATEGOY_UPDATE: "PRODUCT_CATEGOY_UPDATE",
  PRODUCT_CATEGORY_DELETE: "PRODUCT_CATEGORY_DELETE",
  TRANSACTION_VIEW: "TRANSACTION_VIEW",
  TRANSACTION_APPROVE: "TRANSACTION_APPROVE",
  TRANSACTION_REJECT: "TRANSACTION_REJECT",
  PRODUCT_UNIT_VIEW: "PRODUCT_UNIT_VIEW",
  PRODUCT_UNIT_CREATE: "PRODUCT_UNIT_CREATE",
  PRODUCT_UNIT_UPDATE: "PRODUCT_UNIT_UPDATE",
  PRODUCT_UNIT_DELETE: "PRODUCT_UNIT_DELETE",
  CONFIG_GEOLOCATION_VIEW: "CONFIG_GEOLOCATION_VIEW",
  CONFIG_GEOLOCATION_UPDATE: "CONFIG_GEOLOCATION_UPDATE",
  LEADS_VIEW: "LEADS_VIEW",
  LEADS_CREATE: "LEADS_CREATE",
  LEADS_UPDATE: "LEADS_UPDATE",
  LEADS_DELETE: "LEADS_DELETE",
  LEADS_IMPORT: "LEADS_IMPORT",
  LEADS_EXPORT: "LEADS_EXPORT",
  DASHBOARD_VIEW: "DASHBOARD_VIEW",
  DASHBOARD_CREATE: "DASHBOARD_CREATE",
  DASHBOARD_UPDATE: "DASHBOARD_UPDATE",
  DASHBOARD_DELETE: "DASHBOARD_DELETE"
}

const checkPermission = (key: string): boolean => {
  const roles = getCredential().roles

  return roles.includes(key)
}

export { checkPermission, PERMISSION_LIST }
