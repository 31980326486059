import { createApi } from "@reduxjs/toolkit/dist/query/react"
import type { IRenewPasswordResponse, IRenewPasswordPayload } from "./types"
import { API } from "../../utils/constants/url"
import { customFetchBase } from "../interceptor"

export const renewPasswordApi = createApi({
  reducerPath: "renewPasswordApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    renewPassword: builder.mutation<
      IRenewPasswordResponse,
      IRenewPasswordPayload
    >({
      query: (payload) => ({
        url: API.RENEW_PASSWORD.RENEW,
        method: "PUT",
        body: payload
      })
    })
  })
})

export const { useRenewPasswordMutation } = renewPasswordApi
