import React, { useEffect, useState } from "react"
import {
  DashboardIcon,
  DashboardRedIcon,
  DocumentIcon,
  DocumentRedIcon,
  GroupIcon,
  GroupRedIcon,
  ShopIcon,
  ShopRedIcon,
  ProductIcon,
  ProductRedIcon,
  Chevron,
  LeadsIcon,
  LeadsRedIcon,
  WizLogo
  // MoneyRedIcon,
  // MoneyIcon
} from "../../../Assets"
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box
} from "@mui/material"
import { SidebarMenu, SidebarMenuDropdown, ChildMenu } from "./SidebarMenu"
import { useTranslation } from "react-i18next"
import { t } from "i18next"
import { useNavigate, useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../store"
import {
  checkPermission,
  PERMISSION_LIST
} from "../../../utils/checkPermission"
import { LogoCard2 as LogoCard } from "../../atom/LogoCard"
import { setSearchByName } from "../../../pages/UMKM/ListMember/slice"
import { setTransactionId } from "../../../pages/OrdersAndTransactions/slice"
import { useGetWaitingListCounterQuery } from "../../../services/umkm"

interface ISidebar {
  open: boolean
  setOpen: (t: boolean) => void
}
export const Sidebar: React.FC<ISidebar> = (props) => {
  const { open, setOpen } = props

  return (
    <div className={`${open ? "w-[268px]" : "w-16"}  h-screen  fixed z-30 `}>
      <HeadSidebar open={open} setOpen={setOpen} />
      <MenuSidebar open={open} setOpen={setOpen} />
    </div>
  )
}

interface IHeadSidebar extends ISidebar { }
const HeadSidebar: React.FC<IHeadSidebar> = (props) => {
  const { open, setOpen } = props
  return (
    <div
      className="flex items-center h-[72px]  gap-[14px] pl-[18px] "
      style={{
        background: "linear-gradient(90deg, #FF234B 0%, #F78431 100%)"
      }}
    >
      <svg
        onClick={() => {
          setOpen(!open)
        }}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 cursor-pointer text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        />
      </svg>
      <img
        src={WizLogo}
        alt="Wiz Logo"
        className={`w-[160px] h-[31px] ${open ? "visible" : "hidden"}`}
      />
    </div>
  )
}

interface IMenuSidebar extends ISidebar { }
const MenuSidebar: React.FC<IMenuSidebar> = (props) => {
  const { open } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const [UMKMCollapse, setUMKMCollapse] = useState<boolean>(false)
  const [ProductCollapse, setProductCollapse] = useState<boolean>(false)
  const [UsersCollapse, setUsersCollapse] = useState<boolean>(false)
  const [DashboardCollapse, setDashboardCollapse] = useState<boolean>(false)
  const [url, setUrl] = useState<string>("")
  const { waitingState } = useAppSelector((state) => state.umkmWaitingSlice)

  const { data: counterData, refetch } = useGetWaitingListCounterQuery({})

  useEffect(() => {
    void refetch()
  }, [waitingState.waitingTotal])

  useEffect(() => {
    setUrl(location.pathname)
  }, [location])

  const handleCollapse = (name: string): void => {
    if (name === "UMKM") {
      setUMKMCollapse(!UMKMCollapse)
    } else {
      setUMKMCollapse(false)
    }

    if (name === "Product") {
      setProductCollapse(!ProductCollapse)
    } else {
      setProductCollapse(false)
    }

    if (name === "Users") {
      setUsersCollapse(!UsersCollapse)
    } else {
      setUsersCollapse(false)
    }

    if (name === "Dashboard") {
      setDashboardCollapse(!UsersCollapse)
    } else {
      setDashboardCollapse(false)
    }
  }

  const totalUmkmNotification = counterData ? counterData.total : 0

  return (
    <div className="w-full h-full overflow-y-auto border px-2 pt-3 bg-white pb-[70px] flex flex-col justify-between">
      <div>
        {checkPermission(PERMISSION_LIST.DASHBOARD_VIEW) && (
          <SidebarMenuDropdown
            title={t("sidebar.dashboard") || ""}
            onClick={() => {
              handleCollapse("Dashboard")
            }}
            open={open}
            icon={
              (url.includes("/") && url.length <= 1) ||
                url.includes("/kelola-dashboard") ? (
                <DashboardRedIcon
                  className="w-5"
                  title={t("sidebar.dashboard") || ""}
                />
              ) : (
                <DashboardIcon
                  className="w-5"
                  title={t("sidebar.dashboard") || ""}
                />
              )
            }
            alt="Dashboard Icon"
            collapseActive={DashboardCollapse}
            isActive={
              (url.includes("/") && url.length <= 1) ||
              url.includes("/kelola-dashboard")
            }
          >
            <ChildMenu to="/" isActive={url.includes("/") && url.length <= 1}>
              Dashboard
            </ChildMenu>

            <ChildMenu
              to="/kelola-dashboard"
              isActive={url.includes("kelola-dashboard")}
            >
              Kelola Dashboard
            </ChildMenu>
          </SidebarMenuDropdown>
        )}
        {/* <SidebarMenu
          open={open}
          to="/cek-saldo"
          icon={
            url.includes("/cek-saldo") ? (
              <MoneyRedIcon
                className="w-5"
                title={t("sidebar.list-umkm") || ""}
              />
            ) : (
              <MoneyIcon
                className="w-5"
                title={t("sidebar.list-umkm") || ""}
              />
            )
          }
          alt="Document Icon"
          onClick={() => {
          }}
          isActive={url.includes("/cek-saldo")}
        >
          {t("sidebar.cek-saldo") || ""}
        </SidebarMenu> */}

        {(checkPermission(PERMISSION_LIST.UMKM_WAITING_APPROVAL_VIEW) ||
          checkPermission(PERMISSION_LIST.UMKM_MEMBER_VIEW) ||
          checkPermission(PERMISSION_LIST.UMKM_HISTORY_APPROVAL_VIEW)) && (
            <SidebarMenuDropdown
              open={open}
              icon={
                url.includes("/waiting-approval") ||
                  url.includes("/umkm-list-member") ||
                  url.includes("/approval-history") ? (
                  <ShopRedIcon
                    className="w-5"
                    title={t("sidebar.list-umkm") || ""}
                  />
                ) : (
                  <ShopIcon
                    className="w-5"
                    title={t("sidebar.list-umkm") || ""}
                  />
                )
              }
              alt="Store Icon"
              title={t("sidebar.list-umkm") || ""}
              notification={
                totalUmkmNotification > 0 ? totalUmkmNotification : undefined
              }
              onClick={() => {
                handleCollapse("UMKM")
              }}
              collapseActive={UMKMCollapse}
              isActive={
                url.includes("/waiting-approval") ||
                url.includes("/umkm-list-member") ||
                url.includes("/approval-history")
              }
            >
              {checkPermission(PERMISSION_LIST.UMKM_WAITING_APPROVAL_VIEW) && (
                <ChildMenu
                  to="/waiting-approval"
                  notification={
                    counterData
                      ? counterData.total > 0
                        ? counterData.total
                        : undefined
                      : undefined
                  }
                  isActive={url.includes("/waiting-approval")}
                >
                  {t("sidebar.waiting-approval") || ""}
                </ChildMenu>
              )}
              {checkPermission(PERMISSION_LIST.UMKM_MEMBER_VIEW) && (
                <ChildMenu
                  to="/umkm-list-member"
                  isActive={url.includes("/umkm-list-member")}
                >
                  {t("sidebar.umkm-list-member") || ""}
                </ChildMenu>
              )}
              {checkPermission(PERMISSION_LIST.UMKM_HISTORY_APPROVAL_VIEW) && (
                <ChildMenu
                  to="/approval-history"
                  isActive={url.includes("/approval-history")}
                >
                  {t("sidebar.approval-history") || ""}
                </ChildMenu>
              )}
            </SidebarMenuDropdown>
          )}
        {checkPermission(PERMISSION_LIST.LEADS_VIEW) && (
          <SidebarMenu
            open={open}
            to="/leads-data"
            icon={
              url.includes("/leads-data") ? (
                <LeadsRedIcon
                  className="w-4"
                  title={t("sidebar.data-leads") || ""}
                />
              ) : (
                <LeadsIcon
                  className="w-4"
                  title={t("sidebar.data-leads") || ""}
                />
              )
            }
            alt="Document Icon"
            onClick={() => {
              dispatch(setSearchByName(""))
              dispatch(setTransactionId(""))
            }}
            isActive={url.includes("/leads-data")}
          >
            {t("sidebar.data-leads") || ""}
          </SidebarMenu>
        )}
        {checkPermission(PERMISSION_LIST.TRANSACTION_VIEW) && (
          <SidebarMenu
            open={open}
            to="/orders-and-transactions"
            icon={
              url.includes("/orders-and-transactions") ? (
                <DocumentRedIcon
                  className="w-4"
                  title={t("sidebar.orders-and-transactions") || ""}
                />
              ) : (
                <DocumentIcon
                  className="w-4"
                  title={t("sidebar.orders-and-transactions") || ""}
                />
              )
            }
            alt="Document Icon"
            onClick={() => {
              dispatch(setSearchByName(""))
              dispatch(setTransactionId(""))
            }}
            isActive={url.includes("/orders-and-transactions")}
          >
            {t("sidebar.orders-and-transactions") || ""}
          </SidebarMenu>
        )}
        {(checkPermission(PERMISSION_LIST.PRODUCT_VIEW) ||
          checkPermission(PERMISSION_LIST.PRODUCT_CATEGORY_VIEW)) && (
            <SidebarMenuDropdown
              open={open}
              icon={
                url.includes("/master-product") ||
                  url.includes("/category") ||
                  url.includes("/master-unit") ? (
                  <ProductRedIcon
                    className="w-5"
                    title={t("sidebar.master-product") || ""}
                  />
                ) : (
                  <ProductIcon
                    className="w-5"
                    title={t("sidebar.master-product") || ""}
                  />
                )
              }
              alt="Master Produk"
              title={t("sidebar.master-product") || ""}
              onClick={() => {
                handleCollapse("Product")
              }}
              collapseActive={ProductCollapse}
              isActive={
                url.includes("/master-product") ||
                url.includes("/category") ||
                url.includes("/master-unit")
              }
            >
              {checkPermission(PERMISSION_LIST.PRODUCT_VIEW) && (
                <ChildMenu
                  to="/master-product"
                  isActive={url.includes("/master-product")}
                >
                  {t("sidebar.product") || ""}
                </ChildMenu>
              )}
              {checkPermission(PERMISSION_LIST.PRODUCT_CATEGORY_VIEW) && (
                <ChildMenu to="/category" isActive={url.includes("/category")}>
                  {t("sidebar.category") || ""}
                </ChildMenu>
              )}
              {checkPermission(PERMISSION_LIST.PRODUCT_UNIT_VIEW) && (
                <ChildMenu
                  to="/master-unit"
                  isActive={url.includes("/master-unit")}
                >
                  {t("sidebar.unit") || ""}
                </ChildMenu>
              )}
            </SidebarMenuDropdown>
          )}

        {(checkPermission(PERMISSION_LIST.USER_VIEW) ||
          checkPermission(PERMISSION_LIST.ROLE_VIEW)) && (
            <SidebarMenuDropdown
              open={open}
              icon={
                url.includes("/users") || url.includes("/role") ? (
                  <GroupRedIcon
                    className="w-5"
                    title={t("sidebar.management-of-users-and-roles") || ""}
                  />
                ) : (
                  <GroupIcon
                    className="w-5"
                    title={t("sidebar.management-of-users-and-roles") || ""}
                  />
                )
              }
              alt="People Icon"
              title={t("sidebar.management-of-users-and-roles") || ""}
              onClick={() => {
                handleCollapse("Users")
              }}
              collapseActive={UsersCollapse}
              isActive={url.includes("/users") || url.includes("/role")}
            >
              {checkPermission(PERMISSION_LIST.USER_VIEW) && (
                <ChildMenu to="/users" isActive={url.includes("/users")}>
                  {t("sidebar.user") || ""}
                </ChildMenu>
              )}
              {checkPermission(PERMISSION_LIST.ROLE_VIEW) && (
                <ChildMenu to="/role" isActive={url.includes("/role")}>
                  {t("sidebar.roles") || ""}
                </ChildMenu>
              )}
            </SidebarMenuDropdown>
          )}
      </div>
      {open && <LogoCard />}
    </div>
  )
}

interface TMobileMenu extends ISidebar { }
export const MobileMenu: React.FC<TMobileMenu> = (props) => {
  const navigate = useNavigate()
  const { open, setOpen } = props
  const [anggotaUmkmCollapse, setAnggotaUmkmCollapse] = React.useState(false)
  const [dashboardCollapse, setDashboardCollapse] = React.useState(false)
  const [userAndRoleCollapse, setUserAndRoleCollpase] = React.useState(false)

  return (
    <Drawer
      style={{ zIndex: 2200 }}
      anchor={"left"}
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      className="border border-red-600"
    >
      <Box
        component="div"
        sx={{
          width: 250,
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}
        role="presentation"
      // onClick={() => {
      //   setOpen(false)
      // }}
      // onKeyDown={() => {
      //   setOpen(false)
      // }}
      >
        <List sx={{ paddingTop: 0 }}>
          <div
            className="w-full h-[72px] py-[16px] flex flex-col justify-center items-center gap-[10px] mb-2 "
            style={{
              background: "linear-gradient(90deg, #FF234B 0%, #F78431 100%)"
            }}
          >
            <img
              src={WizLogo}
              alt="Wiz Logo"
              className={`w-[160px] h-[31px] ${open ? "visible" : "hidden"}`}
            />
          </div>
          {checkPermission(PERMISSION_LIST.DASHBOARD_VIEW) && (
            <ListItem
              button
              onClick={() => {
                setDashboardCollapse(!dashboardCollapse)
              }}
            >
              <ListItemIcon>
                <DashboardIcon
                  className="w-4"
                  title={t("sidebar.list-umkm") || ""}
                />
              </ListItemIcon>
              <ListItemText primary={<p>{t("sidebar.list-umkm")}</p>} />
              <ListItemIcon>
                <img
                  src={Chevron}
                  alt="Chevron"
                  className={`w-3 ${!dashboardCollapse ? "rotate-180" : ""}`}
                />
              </ListItemIcon>
            </ListItem>
          )}

          <Collapse in={dashboardCollapse} timeout="auto" unmountOnExit>
            <ListItem
              button
              onClick={() => {
                navigate("")
              }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={t("sidebar.waiting-approval")} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                navigate("/umkm-list-member")
              }}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={t("sidebar.umkm-list-member")} />
            </ListItem>
          </Collapse>
          {/* <ListItem button onClick={() => null}>
            <ListItemIcon>
              <DashboardIcon
                className="w-4"
                title={t("sidebar.dashboard") || ""}
              />
            </ListItemIcon>
            <ListItemText primary={<p>{t("sidebar.dashboard")}</p>} />
          </ListItem> */}
          {(checkPermission(PERMISSION_LIST.UMKM_WAITING_APPROVAL_VIEW) ||
            checkPermission(PERMISSION_LIST.UMKM_MEMBER_VIEW) ||
            checkPermission(PERMISSION_LIST.UMKM_MEMBER_VIEW)) && (
              <ListItem
                button
                onClick={() => {
                  setAnggotaUmkmCollapse(!anggotaUmkmCollapse)
                }}
              >
                <ListItemIcon>
                  <ShopIcon
                    className="w-4"
                    title={t("sidebar.list-umkm") || ""}
                  />
                </ListItemIcon>
                <ListItemText primary={<p>{t("sidebar.list-umkm")}</p>} />
                <ListItemIcon>
                  <img
                    src={Chevron}
                    alt="Chevron"
                    className={`w-3 ${!anggotaUmkmCollapse ? "rotate-180" : ""}`}
                  />
                </ListItemIcon>
              </ListItem>
            )}
          <Collapse in={anggotaUmkmCollapse} timeout="auto" unmountOnExit>
            {checkPermission(PERMISSION_LIST.UMKM_WAITING_APPROVAL_VIEW) && (
              <ListItem
                button
                onClick={() => {
                  navigate("/waiting-approval")
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={t("sidebar.waiting-approval")} />
              </ListItem>
            )}
            {checkPermission(PERMISSION_LIST.UMKM_MEMBER_VIEW) && (
              <ListItem
                button
                onClick={() => {
                  navigate("/umkm-list-member")
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={t("sidebar.umkm-list-member")} />
              </ListItem>
            )}
            {checkPermission(PERMISSION_LIST.UMKM_MEMBER_VIEW) && (
              <ListItem
                button
                onClick={() => {
                  navigate("/approval-history")
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={t("sidebar.approval-history")} />
              </ListItem>
            )}
          </Collapse>

          {checkPermission(PERMISSION_LIST.LEADS_VIEW) && (
            <ListItem button onClick={() => null}>
              <ListItemIcon>
                <LeadsIcon
                  className="w-4"
                  title={t("sidebar.data-leads") || ""}
                />
              </ListItemIcon>
              <ListItemText primary={<p>{t("sidebar.data-leads") || ""}</p>} />
            </ListItem>
          )}

          {checkPermission(PERMISSION_LIST.TRANSACTION_VIEW) && (
            <ListItem button onClick={() => null}>
              <ListItemIcon>
                <DocumentIcon
                  className="w-4"
                  title={t("sidebar.orders-and-transactions") || ""}
                />
              </ListItemIcon>
              <ListItemText
                primary={<p>{t("sidebar.orders-and-transactions") || ""}</p>}
              />
            </ListItem>
          )}

          <ListItem button onClick={() => null}>
            <ListItemIcon>
              <ProductIcon
                className="w-4"
                title={t("sidebar.master-product") || ""}
              />
            </ListItemIcon>
            <ListItemText
              primary={<p> {t("sidebar.master-product") || ""}</p>}
            />
          </ListItem>
          {(checkPermission(PERMISSION_LIST.USER_VIEW) ||
            checkPermission(PERMISSION_LIST.ROLE_VIEW)) && (
              <ListItem
                button
                onClick={() => {
                  setUserAndRoleCollpase(!userAndRoleCollapse)
                }}
              >
                <ListItemIcon>
                  <GroupIcon
                    className="w-4"
                    title={t("sidebar.management-of-users-and-roles") || ""}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <p>{t("sidebar.management-of-users-and-roles") || ""}</p>
                  }
                />
              </ListItem>
            )}

          <Collapse in={userAndRoleCollapse} timeout="auto" unmountOnExit>
            {checkPermission(PERMISSION_LIST.USER_VIEW) && (
              <ListItem
                button
                onClick={() => {
                  navigate("/users")
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={t("sidebar.user") || ""} />
              </ListItem>
            )}
            {checkPermission(PERMISSION_LIST.ROLE_VIEW) && (
              <ListItem
                button
                onClick={() => {
                  navigate("/roles")
                }}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={t("sidebar.roles") || ""} />
              </ListItem>
            )}
          </Collapse>
        </List>
      </Box>
    </Drawer>
  )
}
