/* eslint-disable */
import Card from "../../Components/atom/card"
import { WizLogo } from "../../Assets/index"
import { useTranslation } from "react-i18next"
import React from "react"

const TextWithLineBreaks: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="p-6 text-sm">
      {text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  )
}

const PrivacyAndPolicyWizPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="w-full h-full flex items-center justify-center flex-col gap-4 mt-16 mb-5 p-6">
        <Card
          center={true}
          padding={0}
          width={100}
          component={
            <div>
              <div
                className="flex items-center h-[72px] rounded-t-lg"
                style={{
                  background: "linear-gradient(90deg, #FF234B 0%, #F78431 100%)"
                }}
              >
                <img
                  src={WizLogo}
                  alt="Wiz Logo"
                  className="w-[160px] h-[31px] m-auto"
                />
              </div>
              <p className="text-lg font-bold text-center mt-5">{t("common.privacy-and-policy")}</p>
              <TextWithLineBreaks text={t("common.privacy-text")} />
            </div>
          }
        />
      </div>
    </>
  )
}

export default PrivacyAndPolicyWizPage
