import { configureStore } from "@reduxjs/toolkit"
import { usersApi } from "./services/users"
import { umkmApi } from "./services/umkm"
import { leadsApi } from "./services/leads"
import { forgotPasswordApi } from "./services/forgotPassword"
import { useDispatch, useSelector } from "react-redux"
import type { TypedUseSelectorHook } from "react-redux"
import layoutSlice from "./Components/organisms/Layout/slice"
import navbarSlice from "./Components/molecules/Navbar/slice"
import usersSlice from "./pages/Users/slice"
import { loginApi, logoutApi, refreshTokenApi } from "./services/auth"
import umkmWaitingSlice from "./pages/UMKM/WaitingApproval/slice"
import umkmMemberListSlice from "./pages/UMKM/ListMember/slice"
import umkmApprovalHistorySlice from "./pages/UMKM/ApprovalHistory/slice"
import toastSlice from "./Components/molecules/Toast/slice"
import { rolesApi } from "./services/roles"
import authSlice from "./services/auth/slice"
import { categoryApi } from "./services/category"
import { productApi } from "./services/product"
import masterProductSlice from "./pages/MasterProduct/slice"
import { transactionsApi } from "./services/transactions"
import transactionsSlice from "./pages/OrdersAndTransactions/slice"
import { notificationApi } from "./services/notification"
import { dashboardApi } from "./services/dashboard"
import { unitApi } from "./services/unit"
import unitSlice from "./pages/Unit/slice"
import { renewPasswordApi } from "./services/renewPassword"
import universalModalSlice from "./Components/molecules/Alert/UniversalAlert/slice"
export const store = configureStore({
  reducer: {
    layoutSlice,
    navbarSlice,
    usersSlice,
    authSlice,
    umkmWaitingSlice,
    umkmMemberListSlice,
    umkmApprovalHistorySlice,
    masterProductSlice,
    transactionsSlice,
    unitSlice,
    toastSlice,
    universalModalSlice,
    [loginApi.reducerPath]: loginApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [refreshTokenApi.reducerPath]: refreshTokenApi.reducer,
    [logoutApi.reducerPath]: logoutApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [leadsApi.reducerPath]: leadsApi.reducer,
    [umkmApi.reducerPath]: umkmApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [unitApi.reducerPath]: unitApi.reducer,
    [renewPasswordApi.reducerPath]: renewPasswordApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(usersApi.middleware)
      .concat(leadsApi.middleware)
      .concat(umkmApi.middleware)
      .concat(loginApi.middleware)
      .concat(rolesApi.middleware)
      .concat(forgotPasswordApi.middleware)
      .concat(logoutApi.middleware)
      .concat(refreshTokenApi.middleware)
      .concat(categoryApi.middleware)
      .concat(productApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(transactionsApi.middleware)
      .concat(notificationApi.middleware)
      .concat(unitApi.middleware)
      .concat(renewPasswordApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
