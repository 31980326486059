import React from "react"
import { CircularProgress } from "@mui/material"

interface IButton {
  outlined?: boolean
  scretch?: boolean
  onClick?: (value: any) => void
  loading?: boolean
  className?: string
  secondary?: boolean
}

export const Button: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    IButton
> = ({
  children,
  outlined,
  scretch,
  className,
  loading,
  secondary,
  onClick,
  ...rest
}) => (
  <button
    {...rest}
    className={`
      font-bold
      rounded-xl
      text-sm
      px-5
      py-2
      border-[1px]
    ${
      outlined
        ? "text-primary border-primary"
        : rest.disabled
        ? "bg-lightgray2 border-lightgray2 text-white"
        : secondary
        ? "bg-pink1 border-pink1 text-primary"
        : "bg-primary text-white border-primary"
    }
    ${scretch && "w-full"}
    ${(rest.disabled || loading) && "bg-lightgray2 border-lightgray2"}

    ${className}
    `}
    disabled={rest.disabled || loading}
    onClick={onClick}
  >
    {loading ? (
      <CircularProgress sx={{ color: "#ED1B2E" }} size={"1rem"} />
    ) : (
      <>{children}</>
    )}
  </button>
)
