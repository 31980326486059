export const API = {
  AUTH: {
    LOGIN: "/oauth/authenticate/distributor",
    REFRESH_TOKEN: "/oauth/refreshtoken",
    LOGOUT: "/oauth/logout/distributor"
  },
  RENEW_PASSWORD: {
    RENEW: "/user/distributor/change-own-password/first-time"
  },
  CATEGORY: {
    LIST_CATEGORY: "/product-category/list",
    GET_CATEGORY: "/product-category/get",
    CREATE_CATEGORY: "/product-category/create",
    DELETE_CATEGORY: "/product-category/delete",
    UPDATE_CATEGORY: "/product-category/update"
  },
  DASHBOARD: {
    LIST_DASHBOARD: "/dashboard/list",
    DETAIL_DASHBOARD: "/dashboard/detail",
    VIEW_DASHBOARD: "/dashboard/view",
    CREATE_DASHBOARD: "/dashboard/create",
    DELETE_DASHBOARD: "/dashboard/delete",
    UPDATE_DASHBOARD: "/dashboard/update"
  },
  LEADS: {
    LIST_LEADS: "/leads/distributor/data",
    LIST_DISTRIBUTOR: "/leads/distributor/list",
    DETAIL_LEADS: "/leads/distributor/data/detail",
    CREATE_LEADS: "/leads/distributor/data/create",
    UPDATE_LEADS: "/leads/distributor/data/update",
    DELETE_LEADS: "/leads/distributor/data/delete",
    IMPORT_LEADS: "/leads/distributor/data/import",
    EXPORT_LEADS: "/leads/distributor/data/export"
  },
  NOTIFICATION: {
    LIST_NOTIFICATION: "/notification/distributor/list",
    COUNT_UNREAD_NOTIFICATION: "/notification/distributor/unread/counter",
    READ_NOTIFICATION: "/notification/read",
    GET_LATEST_NOTIFICATION: "/notification/distributor/counter/latest"
  },
  PRODUCT: {
    LIST_PRODUCT: "/product/list",
    UPLOAD_IMAGE_PRODUCT: "/file/product/image/str/upload",
    GET_IMAGE_PRODUCT: "/file/product/image/get",
    CREATE_PRODUCT: "/product/create",
    UPDATE_PRODUCT: "/product/update",
    ACTIVATE_PRODUCT: "/product/activation",
    DETAIL_PRODUCT: "/product/get",
    DELETE_PRODUCT: "/product/delete"
  },
  ROLE: {
    LIST_ROLE: "/role/list",
    DETAIL_ROLE: "/role/get",
    CREATE_ROLE: "/role/create",
    DELETE_ROLE: "/role/deletes",
    UPDATE_ROLE: "/role/update",
    LIST_PERMISSION_ROLE: "/role/permission-hierarchy"
  },
  TRANSACTION: {
    LIST_TRANSACTION: "/transaction/dist/list",
    DETAIL_TRANSACTION: "/transaction/dist/list/detail",
    GET_REASON: "/transaction/master/approval/reason/list",
    GET_CANCEL_REASON: "/transaction/master/cancellation/reason/list",
    GET_DELIVERY_REASON: "/transaction/master/delivery/reason/list",
    APPROVE_TRANSACTION: "/transaction/dist/approval",
    CANCEL_TRANSACTION: "/transaction/distributor/cancel",
    LIST_CANVASSER: "/user/canvasser/delivery/list",
    ASSIGN_CANVASSER: "/transaction/distributor/delivery",
    UPDATE_ETD_TRANSACTION: "/transaction/distributor/update",
    DETAIL_DELIVERY_TRANSACTION: "/transaction/distributor/delivery/list",
    LIST_RELATED_TRANSACTION: "/transaction/distributor/related"
  },
  UMKM: {
    LIST_WAITING_APPROVAL: "/user/dist/list-umkm/waiting-approval",
    LIST_MEMBER: "/user/dist/list-umkm/member",
    LIST_APPROVAL_HISTORY: "/user/dist/list-umkm/history-approval",
    GET_WAITING_APPROVAL_COUNTER:
      "/user/dist/list-umkm/waiting-approval/counter",
    DETAIL_UMKM: "/user/dist/list-umkm/detail",
    LIST_REASON_UMKM: "/user/master/approval/reason/umkm-registration",
    APPROVE_UMKM: "/user/dist/approval/umkm-registration",
    LIST_UMKM_TYPE: "/user/master/distributor/umkm-type",
    GET_KTP_IMAGE_UMKM: "/file/registration/image/zip/get"
  },
  UNIT: {
    LIST_UNIT: "/product-unit/list",
    CREATE_UNIT: "/product-unit/create",
    UPDATE_UNIT: "/product-unit/update",
    DELETE_UNIT: "/product-unit/delete"
  },
  USER: {
    LIST_USER: "/user/distributor/list",
    LIST_ROLE_USER: "/role/list",
    DETAIL_USER: "/user/distributor",
    CREATE_USER: "/user/distributor/create",
    UPDATE_USER: "/user/distributor/update",
    DELETE_USER: "/user/distributor/deletes",
    RESET_PASSWORD: "/user/dist/reset-password",
    UPDATE_PASSWORD: "/user/distributor/change-password",
    UPDATE_PROFILE_USER: "/user/distributor/profile/update",
    GET_PROFILE: "/user/distributor/profile/get",
    GET_LOCATION_USER: "/user/distributor/config/geolocation/data",
    UPDATE_LOCATION_USER: "/user/distributor/config/geolocation/update"
  },
  SALDO: {
    BALANCE: "/transaction/withdrawal/balance",
    HISTORY: "/transaction/withdrawal/history"
  }
}
