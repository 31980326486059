import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type {
  IGetLeadsList,
  IGetLeadsByIDResponse,
  IGetLeadsByID,
  ICreateLeads,
  ICreateLeadsResponse,
  IDeleteLeads,
  IUpdateLeads,
  IUpdateLeadsResponse,
  IImportLeads,
  IImportResponse,
  IExportResponse
} from "./types"

export const leadsApi = createApi({
  reducerPath: "leadsApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getLeadsList: builder.query<any, IGetLeadsList>({
      query: ({
        pageIndex,
        limit,
        sortBy,
        direction,
        search,
        filterByStatus
      }) => ({
        url: API.LEADS.LIST_LEADS,
        method: "GET",
        params: {
          pageIndex,
          limit,
          sortBy,
          direction,
          filterByStatus,
          search
        }
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = res.data.map((data: any, i: number) => ({
          ...{
            id: data.id,
            ownerFullName: data.ownerFullName,
            phoneNumber: data.phoneNumber,
            umkmName: data.umkmName,
            umkmAddress: data.umkmAddress,
            bankBranchName: data.bankBranchName,
            averageTransaction: data.averageTransaction,
            monthlyAverageTransaction: data.monthlyAverageTransaction,
            plafonRecommendation: data.plafonRecommendation,
            isHavingOtherBankAccount: data.isHavingOtherBankAccount,
            registrationStatus: data.registrationStatus,
            transactionMethod: data.transactionMethod,
            topDay: data?.topDay
          }
        }))
        data.total = res.total
        res = data
        return res
      }
    }),
    getLeadById: builder.query<IGetLeadsByIDResponse, IGetLeadsByID>({
      query: ({ id }) => ({
        url: `${API.LEADS.DETAIL_LEADS}/${id}`,
        method: "GET"
      }),
      transformResponse: (res: any, meta, arg) => {
        const data = {
          id: res.data.id,
          ownerFullName: res.data.ownerFullName,
          phoneNumber: res.data.phoneNumber,
          umkmName: res.data.umkmName,
          umkmAddress: res.data.umkmAddress,
          bankBranchName: res.data.bankBranchName,
          averageTransaction: res.data.averageTransaction,
          monthlyAverageTransaction: res.data.monthlyAverageTransaction,
          plafonRecommendation: res.data.plafonRecommendation,
          isHavingOtherBankAccount: res.data.isHavingOtherBankAccount,
          registrationStatus: res.data.registrationStatus,
          transactionMethod: res.data.transactionMethod,
          topDay: res.data.topDay,
          umkmUserId: res.data.umkmUserId
        }
        res = data
        return res
      }
    }),
    createLeads: builder.mutation<ICreateLeadsResponse, ICreateLeads>({
      query: (payload) => ({
        url: API.LEADS.CREATE_LEADS,
        method: "POST",
        body: payload
      })
    }),
    updateLeads: builder.mutation<IUpdateLeadsResponse, IUpdateLeads>({
      query: (payload) => ({
        url: API.LEADS.UPDATE_LEADS,
        method: "PUT",
        body: payload
      })
    }),
    deleteLeads: builder.mutation<boolean, IDeleteLeads>({
      query: ({ id }) => ({
        url: `${API.LEADS.DELETE_LEADS}/${id}`,
        method: "DELETE"
      })
    }),
    importLeads: builder.mutation<IImportResponse, IImportLeads>({
      query: (payload) => ({
        url: API.LEADS.IMPORT_LEADS,
        method: "POST",
        body: payload
      })
    }),
    exportLeads: builder.query<IExportResponse, IGetLeadsList>({
      query: (params) => ({
        url: API.LEADS.EXPORT_LEADS,
        method: "GET",
        params
      })
    })
  })
})

export const {
  useGetLeadsListQuery,
  useCreateLeadsMutation,
  useDeleteLeadsMutation,
  useUpdateLeadsMutation,
  useGetLeadByIdQuery,
  useImportLeadsMutation,
  useExportLeadsQuery,
  useLazyExportLeadsQuery
} = leadsApi
