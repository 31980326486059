import moment from "moment"
import { unparse } from "papaparse"
import { useTranslation } from "react-i18next"
import "moment/locale/id"
moment.locale("id")

export const t = (value: string): string => {
  const { t } = useTranslation()

  return t(value || "")
}

export const formatDate = (date: string, format: string): string => {
  const formattedDate = moment(date).format(format)
  return !date ? "-" : formattedDate
}

export const truncateString = (string: string, maxLength: number): string => {
  const truncate = string.slice(0, maxLength)
  if (string.length > maxLength) {
    return `${truncate}...`
  } else {
    return string
  }
}

export const handleTime = (
  val: any,
  limit: number,
  setTime: React.SetStateAction<any>
): void => {
  const number = /[0-9]/

  !val.target.value
    ? setTime("00")
    : parseInt(val.target.value) > limit
    ? setTime(limit.toString())
    : number.test(val.target.value)
    ? setTime(val.target.value)
    : val.preventDefault()
}

export const setRupiah = (val: number): string => {
  return `Rp${new Intl.NumberFormat("id-ID", {
    style: "decimal"
  }).format(val)}`
}

export const sanitizeUrl = (url: string): string => {
  return url.replaceAll("&amp;", "&")
}

export const allArrayEqual = (arr: any): boolean => {
  return new Set(arr).size === 1
}

export const downloadCSV = (
  array: any,
  headers: any,
  quotes: boolean | boolean[],
  fileName: string
): void => {
  const csv = unparse(array, {
    header: false,
    quotes
  })

  const headerColumn = unparse({ fields: headers, data: [] }, { quotes: true })

  const combinedCSV = `${headerColumn}${csv}`

  const csvData = new Blob([combinedCSV], { type: "text/csv;charset=utf-8;" })

  const csvURL = window.URL.createObjectURL(csvData)

  const testLink = document.createElement("a")
  testLink.href = csvURL
  testLink.setAttribute("download", `${fileName}.csv`)
  testLink.click()
}
