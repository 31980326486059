import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { customFetchBase } from "../interceptor"
import { API } from "../../utils/constants/url"
import type {
  IResponse,
  IGetUnitList,
  ICreateUnit,
  IUpdateUnit,
  IDeleteUnit,
  IUnitListData
} from "./types"

export const unitApi = createApi({
  reducerPath: "unitApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getUnitList: builder.query<IUnitListData, IGetUnitList>({
      query: ({ pageIndex, limit, sortBy, direction, search }) => ({
        url: API.UNIT.LIST_UNIT,
        method: "GET",
        params: {
          pageIndex,
          limit,
          sortBy,
          direction,
          search
        }
      }),
      transformResponse: (res: IResponse) => {
        const data = res.data.map((item: any) => ({
          ...{
            id: item.id,
            code: item.code,
            name: item.name,
            description: item.description,
            totalProduct: item.totalProduct,
            data: {
              id: item.id,
              code: item.code,
              name: item.name,
              description: item.description,
              totalProduct: item.totalProduct
            }
          }
        }))

        const response: IUnitListData = {
          data,
          total: res.total
        }
        return response
      }
    }),
    createUnit: builder.mutation<any, ICreateUnit>({
      query: (payload) => ({
        url: API.UNIT.CREATE_UNIT,
        method: "POST",
        body: payload
      })
    }),
    updateUnit: builder.mutation<IResponse, IUpdateUnit>({
      query: (payload) => ({
        url: API.UNIT.UPDATE_UNIT,
        method: "PUT",
        body: payload
      })
    }),
    deleteUnit: builder.mutation<IResponse, IDeleteUnit>({
      query: ({ id }) => ({
        url: `${API.UNIT.DELETE_UNIT}/${id}`,
        method: "DELETE"
      })
    })
  })
})

export const {
  useGetUnitListQuery,
  useCreateUnitMutation,
  useUpdateUnitMutation,
  useDeleteUnitMutation
} = unitApi
