import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "../../../Components/atom/button"
import Modal from "../../../Components/atom/modal"
import { useNavigate } from "react-router-dom"

interface ICancelModal {
  openCancelModal: boolean
  setOpenCancelModal: React.Dispatch<React.SetStateAction<boolean>>
  title?: string
}

export const CancelModal: React.FC<ICancelModal> = (props) => {
  const { openCancelModal, setOpenCancelModal, title } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal
      isOpen={openCancelModal}
      toggle={() => {
        setOpenCancelModal(false)
      }}
      title={title}
    >
      <div className="w-[380px] h-[160px] flex flex-col justify-between">
        <span className="flex flex-col gap-2">
          <p className="text-[16px] text-secondary">
            {t("navbar.confirm-cancel-message")}
          </p>
        </span>

        <div className="flex w-full gap-4 h-[45px]">
          <Button
            className="w-[50%]"
            outlined={true}
            onClick={() => {
              setOpenCancelModal(false)
            }}
          >
            {t("button.no") || ""}
          </Button>
          <Button
            className="w-[50%]"
            onClick={() => {
              setOpenCancelModal(false)
              navigate(-1)
            }}
          >
            {t("button.yes-back") || ""}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
