import { LoadingCircle } from "./loadingCircle"
import React from "react"
import { WizLogo } from "../../../Assets"

const Loading: React.FC<{ placedOnContainer?: boolean }> = ({
  placedOnContainer = false
}) => {
  return (
    <div
      className={`w-full ${
        placedOnContainer ? "h-full" : "h-screen"
      } flex justify-center items-center flex-col gap-4`}
    >
      <div className="flex justify-center text-center items-center mt-[150px]">
        <p className="font-lato text-gray-700 font-bold">
          <span className="flex justify-center items-center gap-4">
            <img src={WizLogo} alt="test" />
          </span>
        </p>
      </div>
      <LoadingCircle />
    </div>
  )
}
export default Loading
